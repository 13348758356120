import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(()=>{
    window.location.replace('https://forms.gle/gA38VEnKnnaiLG2g8');
  }, []);

  return (
    <>
    </>
  );
}


export default App;
